<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <label>Show</label> -->
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <!-- <label>entries</label> -->
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
              />
              <b-button variant="primary" :to="{ name: 'studio_create' }">
                <span class="text-nowrap">{{ $t("Add Facility") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        hover
        ref="refUserListTable"
        class="text-left position-relative"
        striped
        :per-page="perPage"
        :current-page="currentPage"
        :items="studioData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No Matching Creates Found')"
        :sort-desc.sync="isSortDirDesc"
        :filter="filter"
        :filter-included-fields="filtered"
        @filtered="onFiltered"
        :empty-filtered-text="$t('There are no records matching your request!')"
      >
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">@{{ data.item.username }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{
              data.item.role
            }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <template v-if="isMobile===true">
              <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                />
                </template>

                <b-dropdown-item :to="{name:'studio_edit', params: {studioID: data.item.company_branch_id}}">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">{{$t('Edit')}}</span>
                </b-dropdown-item>

                <b-dropdown-item :to="{name:'studio_user_list', params: {studioID: data.item.company_branch_id}}">
                  <feather-icon icon="UserIcon" />
                  <span class="align-middle ml-50">{{$t('Users')}}</span>
                </b-dropdown-item>

                <b-dropdown-item @click="go_licence_page(data.item.name,data.item.company_branch_id)">
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">{{$t('Licence')}}</span>
                </b-dropdown-item>

                <b-dropdown-item  :variant="data.item.is_active === '0' || data.item.is_active === false   ? 'outline-success' :'outline-danger'"
                @click="success(data.item.company_branch_id,data.item.is_active)">
                  <feather-icon icon="ActivityIcon" />
                  <span class="align-middle ml-50">
                    {{ data.item.is_active === "0"  || data.item.is_active === false   ? $t("Activate")  : $t("Deactivate") }}
                  </span>
                </b-dropdown-item>
              </b-dropdown>
          </template>

          <template v-if="isMobile===false">
            <b-button
              size="sm"
              class="mr-1"
              variant="success"
              :to="{ name: 'studio_user_list', params: { studioID: data.item.company_branch_id  } }">{{ $t("Users") }}</b-button
            >
            <b-button
              size="sm"
              class="mr-1"
              variant="dark"
              @click="go_licence_page(data.item.name,data.item.company_branch_id)"
              >{{ $t("Licence") }}</b-button
            >
            <b-button
                size="sm"
                class="mr-1"
                variant="primary"
                :to="{
                name: 'studio_edit',
                params: { studioID: data.item.company_branch_id },
              }"
            >{{ $t("Edit") }}</b-button>
            <b-button
              size="sm"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              :variant="data.item.is_active === '0'  || data.item.is_active === false  ? 'success' :'danger'"
              @click="success(data.item.company_branch_id,data.item.is_active)"
              >{{ data.item.is_active === "0"  || data.item.is_active === false ? $t("Activate")  : $t("Deactivate") }}</b-button>

              <b-button
              size="sm"
              class="mr-1 ml-1"
              variant="warning"
              :to="{
                name: 'studio_payment_add',
                params: { studioID: data.item.company_branch_id },
              }"
              >{{ $t("Paratika") }}</b-button>
          </template>

        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
            <b-col
            cols="6"
            sm=""
            class="
              d-flex
              align-items-left
              justify-content-right justify-content-sm-start
              radius 0
            "
          >
          <b-form-select v-model="selected" :options="options" @change="changeFiltering" class="select-config">
          </b-form-select>
          </b-col>
          <small v-if="$i18n.locale === 'tr'" class="mt-1">içerisindeki {{totalRows}} kayıttan {{currentPage*1}}-{{currentPage*perPage}} arası ({{currentPage}}.sayfa) gösteriliyor </small>
          <small v-if="$i18n.locale === 'en'" class="mt-1">showing {{currentPage*1}}-{{currentPage*perPage}} (page {{currentPage}}) of {{totalRows}} records</small>
          <!-- Pagination -->

           <b-col
            cols="6"
            sm=""
            class="
              d-flex
              align-items-right
              justify-content-right justify-content-sm-end
              radius 0
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              class="mb-0 mt-1 mt-sm-0 r-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BFormSelect,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import store from "@/store";
export default {
  directives: {
    Ripple,
  },
  components: {
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },

  data() {
    return {
      currentPage: 1,
      totalRows: 1,
      isSortDirDesc: false,
      sortDesc: false,
      sortBy: "id",
      filter: null,
      perPageOptions: [10, 25, 50, 100],
      perPage: 25,
      isAddNewUserSidebarActive: false,
      studioData: [],
      filtered: [],
      tableColumns: [
        { key: "name", label: this.$t("FACILITY NAME"), sortable: true },
        { key: "actions", label: this.$t("TRANSACTIONS") },
      ],
      selected: 'tüm',
      options: [
          { text: this.$t('All Facilities'), value: 'tüm' },
          { text: this.$t('Active Facilities'), value: 'aktif' },
          { text: this.$t('Deactive Facilities'), value: 'deaktif' },
        ]
    };
  },
  computed: {
    sortOptions()
     {
      return this.studioData
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch:{
     studioData(val){
      this.totalRows = val.length
    }
  },
  mounted(){
    this.totalRows = this.studioData.length
  },
  methods: {
      async go_licence_page(name,id){
        await this.commit(name)
          this.$router.push({ name: 'studio_licence', params: { studioID:id}})
      },
      commit(name){

          store.commit('app/breadcrumb',name)
      },
     success(val,is_active){this.$swal({
        title: this.$t('Are you sure?'),
        text: this.$t('Are You Sure You Want To Change The Facility Activity Status?'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes"),
        cancelButtonText:  this.$t("No"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          if(is_active === '0'  || is_active === false  ){
        this.$database.DistributorService.studio_deactive(val, true).then(res => {
          // console.log(res);
          // console.log(val);
          if (!res.is_success) {
            this.$functions.Messages.error('Action failed!')
            this.getStudioData();
          }
          else {
            this.$functions.Messages.success('Success')
            this.getStudioData();
          }
        })
      }
      if(is_active === '1' || is_active === true){
          this.$database.DistributorService.studio_deactive(val, false).then(res => {
              // console.log(res);
            // console.log(val);
            if (!res.is_success) {
                this.$functions.Messages.error('Action failed!')
                this.getStudioData();
            }
            else {
                this.$functions.Messages.success('Success')
                this.getStudioData();
            }
          })
        }
      }
      })
    },

    getStudioData() {
      let me = this;
      this.$database.DistributorService.get_studio_list().then((res) => {
        if (res.is_success) {
          me.studioBaseData = res.result;
          me.studioData = res.result;
          this.totalRows = res.result.length;
        }
      });
    },
     onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    //stüdyoların aktiflik, deaktiflik durumlarına göre filtrelenebilmesi
    changeFiltering(arg) {
      // console.log("filtre değişti");
        if (arg === 'tüm') {
          // console.log("tüm");
          this.studioData = this.studioBaseData;
        } else if (arg === 'aktif') {
          // console.log("aktif");
          // console.log(this.studioData);
          //aktif seçilirse base veriler içinde map et aktif true olanları tablo verisi olarak al
          let data = [];
          this.studioBaseData.map((x) => {
            // console.log(x);
            if (x.is_active === '1' || x.is_active === true) {
              data.push(x);
              this.totalRows= data.length;
              this.currentPage = 1
            }
          });
          this.studioData = data;
          // console.log("tüm");
          // console.log(this.studioData);
        } else if (arg === 'deaktif') {
          //deaktif seçilirse base veriler içinde map et aktif false olanları tablo verisi olarak al
          let data = [];
          this.studioBaseData.map((x) => {
            if (x.is_active === '0' || x.is_active === false) {
              data.push(x);
              this.totalRows = data.length;
              this.currentPage = 1
            }
          });
          this.studioData = data;
          // console.log("deaktif");
          // console.log(this.studioData);
      } else {
        //null gelebilir..
        this.studioData = this.studioBaseData;
      }
    },
  },
  created() {
    this.getStudioData();
  },
};
</script>

<style lang="scss" src="@/styles/scss/studioListScoped.scss" scoped></style>
